<template>
  <div class="d-flex justify-content-center align-items-center">
    <ek-dialog placeholder="ابحث عن سؤال" class="w-100" @search="setSearch"> </ek-dialog>
    <b-button variant="primary" class="w-25" @click="goToNewQuiz"
      >اضف سؤال <unicon class="ml-1" name="plus" width="15" height="15" fill="#fff"
    /></b-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BToast } from "bootstrap-vue";
export default {
  props: ["lessonId"],
  methods: {
    goToNewQuiz() {
      if (this.quizDto.quizId) {
        this.$router.push({
          path: `/admin/questionsBank/${this.quizDto.quizId}/0`,
          query: { questionType: 2 },
        });
      } else {
        const bToast = new BToast();
        bToast.$bvToast.toast("اختر اختبار", {
          title: "الاختبار  مطلوب",
          variant: "danger",
          toaster: "b-toaster-top-right",
          solid: true,
          autoHideDelay: 2000,
          appendToast: true,
        });
      }
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["content", "order", "quizId"],
        query,
      });
    },
  },
  computed: {
    ...mapState({
      quizDto: ({ questionsBank }) => questionsBank.quizDto,
    }),
  },
};
</script>

<style></style>
